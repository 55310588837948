<template>
  <gmap-marker
    :icon="{ ...icon, labelOrigin: { x: 30, y: -5 } }"
    :visible="visibled"
    :position="{
      lat: position.lat,
      lng: position.lng,
      labelOrigin: { x: 0, y: -100 },
    }"
    :label="{
      text: contratista ? contratista : ' ',
      color: 'white',
      fontSize: '11px',
      className: 'allied-marker__label',
    }"
    @click="$emit('click', $event)"
  >
  </gmap-marker>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    angle: {
      type: Number,
      default: 0,
    },
    position: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    lastConnection: {
      type: String,
      default: '',
    },
    contratista: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeout: false,
      frames: [],
      frame: '',
      frameNumber: 0,
      requestAnimation: undefined,
      timeWhenLastUpdate: null,
      timeFromLastUpdate: null,
      pause: false,
      connectionInterval: null,
      visibled: true,
    };
  },
  computed: {
    localVisibility: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('on-visibility', value);
        }
      },
    },
    icon() {
      return {
        url: this.frame,
        size: { width: 60, height: 60, f: 'px', b: 'px' },
        scaledSize: { width: 60, height: 60, f: 'px', b: 'px' },
      };
    },
    animationDuration() {
      return 100 * this.totalFrames;
    },

    timePerFrame() {
      return this.animationDuration / this.totalFrames;
    },
    totalFrames() {
      return this.frames.length;
    },
  },
  watch: {
    angle(newVal, oldVal) {
      if (Math.abs(oldVal - newVal) >= 45) {
        if (this.timeout) {
          this.timeout = false;

          newVal = this.correctAngle(newVal);
          oldVal = this.correctAngle(oldVal);
          this.rotate(newVal, oldVal);
        }
      }
    },
  },
  destroyed() {
    clearInterval(this.connectionInterval);
  },

  mounted() {
    this.toggleVisibleLastConnection();
    this.connectionInterval = setInterval(() => {
      this.toggleVisibleLastConnection();
    }, 60000);

    this.frame = require('@/assets/images/contratistas/blue_vespa/vespa_' +
      this.correctAngle(this.angle) +
      '.png');
  },
  methods: {
    toggleVisibleLastConnection() {
      let diferenceInMinutes = moment(new Date()).diff(
        this.lastConnection,
        'minutes'
      );
      const expire = 10;
      if (diferenceInMinutes >= expire) {
        this.visibled = false;
      } else {
        this.visibled = true;
      }
    },
    correctAngle(angle) {
      return this.roundFive(this.orientedAngle(angle));
    },
    orientedAngle(angle) {
      return angle + Math.ceil(-angle / 360) * 360;
    },
    roundFive(x) {
      return Math.ceil(x / 5) * 5;
    },

    frameByFrameAnimation(startTime) {
      if (this.pause) {
        return;
      }
      if (!this.timeWhenLastUpdate) this.timeWhenLastUpdate = startTime;
      this.timeFromLastUpdate = startTime - this.timeWhenLastUpdate;

      if (this.timeFromLastUpdate > this.timePerFrame) {
        this.frame = this.frames[this.frameNumber];

        this.timeWhenLastUpdate = startTime;

        if (this.frameNumber >= this.totalFrames - 1) {
          cancelAnimationFrame(this.requestAnimation);
          this.requestAnimation = undefined;
          this.pause = true;
          this.frameNumber = 0;
          this.timeout = true;
        } else {
          this.frameNumber = this.frameNumber + 1;
        }
      }

      this.requestAnimation = window.requestAnimationFrame(
        this.frameByFrameAnimation
      );
    },

    rotate(newVal, oldVal) {
      this.frames = [];
      this.pause = false;
      let giro = 5;
      if (
        (newVal - oldVal > 0 && 2 * (newVal - oldVal) > 360) ||
        (newVal - oldVal < 0 && 2 * (oldVal - newVal) < 360)
      ) {
        giro = -5;
      }

      while (oldVal !== newVal) {
        oldVal += giro;
        oldVal = this.correctAngle(oldVal);

        this.frames.push(
          require('@/assets/images/contratistas/blue_vespa/vespa_' +
            oldVal +
            '.png')
        );
      }

      this.requestAnimation = window.requestAnimationFrame(
        this.frameByFrameAnimation
      );
    },
  },
};
</script>

<style lang="scss">
.allied-marker__label {
  background: #00d0eb;
  padding: 8px;
  border-radius: 8px;
}
</style>
