import { onMounted, ref } from '@vue/composition-api';

export function useGeolocation() {
  const errorMessage = ref('');
  const location = ref();
  const coordinates = ref({
    lat: 7.113,
    lng: -73.114,
  });
  const gettingLocation = ref(false);
  onMounted(() => {
    // Do we support geolocation
    if (!('geolocation' in navigator)) {
      errorMessage.value = 'Geolocation is not available.';
      return;
    }

    gettingLocation.value = true;
    // Get current position

    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        gettingLocation.value = false;
        location.value = pos;
        coordinates.value = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
      },
      (err) => {
        gettingLocation.value = false;
        errorMessage.value = err.message;
      }
    );
  });

  return { errorMessage, location, gettingLocation, coordinates };
}
