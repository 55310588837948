var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-marker',{attrs:{"icon":Object.assign({}, _vm.icon, {labelOrigin: { x: 30, y: -5 }}),"visible":_vm.visibled,"position":{
    lat: _vm.position.lat,
    lng: _vm.position.lng,
    labelOrigin: { x: 0, y: -100 },
  },"label":{
    text: _vm.contratista ? _vm.contratista : ' ',
    color: 'white',
    fontSize: '11px',
    className: 'allied-marker__label',
  }},on:{"click":function($event){return _vm.$emit('click', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }