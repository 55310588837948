<template>
  <div>
    <vx-card>
      <GmapMap
        :center="center"
        :zoom="14"
        :options="mapOptions"
        style="width: 100%; height: 600px"
      >
        <AlliedMarker
          v-for="allie in allieMarkers"
          :key="'contratista-' + allie.id"
          :position="allie.position"
          :contratista="allie.code"
          :angle="allie.angle"
          v-if="allie.visible"
          :visible="allie.visible"
          :updated-at="allie.updated_at"
          :last-connection="allie.last_connection"
          @click="center = allie.position"
        />

        <StoreMarker
          :key="'franchise-' + store.id"
          v-for="store in storeMarkers"
          :visible="store.visible"
          :position="store.position"
          @click="center = store.position"
        />
      </GmapMap>
    </vx-card>
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { FRANCHISES, COVERAGE_NAME } from '@/graphql/queries';
// import { DELIVERYMAN_UPDATED_SUBSCRIPTION } from '@/graphql/subscriptions';
import mapOptions from '../map-options';
import AlliedMarker from '@/components/AlliedMarker';
import StoreMarker from '@/components/StoreMarker';
import { useGeolocation } from '@/composable/useGeolocation';
export default defineComponent({
  components: {
    AlliedMarker,
    StoreMarker,
  },
  // eslint-disable-next-line no-unused-vars
  setup(_, { root }) {
    // const { result: deliverymenResult } = useQuery(DELIVERYMEN, {
    //   status: 'ENABLED',
    // });
    const { result: franchisesResult } = useQuery(FRANCHISES);
    const { onResult: onCoverageNameResult } = useQuery(COVERAGE_NAME);

    const { coordinates } = useGeolocation();

    const allieMarkers = computed(
      () => root.$store.state.deliverymen.allyMarkers
    );
    const storeMarkers = useResult(franchisesResult, [], (data) =>
      data.franchises.map((f) => {
        return {
          ...f,
          position: { lat: f.coordinates.lat, lng: f.coordinates.lng },
          visible: true,
        };
      })
    );

    // eslint-disable-next-line no-unused-vars
    // const getHeading = (point, oldPoint) => {
    //   // get angle between two points
    //   const angleInDegrees =
    //     (Math.atan2(point.lat - oldPoint.lat, point.lng - oldPoint.lng) * 180) /
    //     Math.PI;

    //   // move heading north
    //   return -90 + angleInDegrees;
    // };

    // const enabled = ref(true);
    // const { result: deliverymanSubResult } = useSubscription(
    //   DELIVERYMAN_UPDATED_SUBSCRIPTION,
    //   () => ({
    //     enabled: enabled.value,
    //   })
    // );

    // watch(
    //   deliverymanSubResult,
    //   (newVal, oldVal) => {
    //     if (oldVal && oldVal.deliverymanUpdated) {
    //       /*  const oldLocation = oldVal.deliverymanUpdated.last_location;
    //       console.log(
    //         '🚀 ~ file: Mapa.vue ~ line 75 ~ setup ~ oldLocation',
    //         oldLocation
    //       );
    //       const lastLocation = newVal.deliverymanUpdated.last_location;
    //       console.log(
    //         '🚀 ~ file: Mapa.vue ~ line 80 ~ setup ~ lastLocation',
    //         lastLocation
    //       ); */
    //       /*   const allie = allieMarkers.value.find(
    //         (m) => m.id == newVal.deliverymanUpdated.id
    //       );
    //       console.log('🚀 ~ file: Mapa.vue ~ line 79 ~ setup ~ allie', allie); */
    //       /* const angle = Math.ceil(360 - getHeading(lastLocation, oldLocation));
    //       console.log(angle);
    //       allie.angle = angle; */
    //     }
    //   },
    //   {
    //     deep: true,
    //   }
    // );

    root.$socket.client.on('deliverymanUpdated', (data) => {
      console.log('🚀 ~ data', data);
      root.$store.commit('deliverymen/UPDATE_ALLY_LOCATION', data);
    });
    const center = ref(coordinates.value);
    onCoverageNameResult((queryResult) => {
      console.log(
        '🚀 ~ file: Mapa.vue ~ line 135 ~ onCoverageNameResult ~ queryResult',
        queryResult
      );
      if (
        queryResult.data &&
        queryResult.data.coverageName &&
        queryResult.data.coverageName.coordinates
      ) {
        coordinates.value = {
          lat: queryResult.data.coverageName.coordinates.lat,
          lng: queryResult.data.coverageName.coordinates.lng,
        };

        center.value = coordinates.value;
      }
    });
    onMounted(() => {
      root.$store.dispatch('deliverymen/getAllies');
    });
    return { center, mapOptions, allieMarkers, storeMarkers };
  },
});
</script>
