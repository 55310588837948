<template>
  <gmap-marker
    v-bind="$attrs"
    :icon="icon"
    :position="{ lat: position.lat, lng: position.lng }"
  >
  </gmap-marker>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    iconMarkerUrl: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
      default: 60,
    },
    position: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const icon = computed(() => {
      return props.iconMarkerUrl
        ? {
            url: props.iconMarkerUrl,
            size: {
              width: props.width,
              height: props.height,
              f: 'px',
              b: 'px',
            },
            scaledSize: {
              width: props.width,
              height: props.height,
              f: 'px',
              b: 'px',
            },
          }
        : '';
    });
    return {
      icon,
    };
  },
});
</script>

<style lang="scss" scoped></style>
